<template>
  <div>
    <product-detail
      v-if="modal"
      @close="modal = false"
      :productId="product.id"
      ref="productDetail"
      ></product-detail>

    <v-card
      rounded="xl"
      @click="openModal"
      >
      <v-img
        v-if="product.images.length"
        :src="url + product.images[0].url"
        height="150"
        >
        <div
          style="position: absolute; bottom: 8px; left: 8px"
          >
          <div
            class="font-weight-medium"
            >
            {{ product.name }}
          </div>
          <div
            class="font-weight-medium"
            >
            {{ product.displayPrice }}
          </div>
        </div>
      </v-img>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const ProductDetail = () => import('@/components/products/Product')

export default {
  data: () => ({
    modal: false
  }),

  props: {
    product: {
      required: true,
      type: Object
    }
  },

  components: {
    ProductDetail
  },

  methods: {
    openModal () {
      this.modal = true
      setTimeout( () => {
        this.$refs.productDetail.dialog = true
      }, 100)
    }
  },

  computed: {
    ...mapGetters(['url'])
  },
}
</script>
